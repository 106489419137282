<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="35%"
    :before-close="handleClose"
    :destroy-on-close="true"
     @opened="onDialogOpen"
  >
    <el-form
      label-width="90px"
      size="mini"
      :rules="rules"
      :model="formdata"
      class="add-form"
      ref="addForm"
    >
      <el-form-item label="设备类型">
        <el-select
          v-model="formdata.type"
          placeholder="请选择"
        >
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="ID"
        prop="id"
      >
        <el-input v-model="formdata.id"></el-input>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-input v-model="formdata.name"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="formdata.address"></el-input>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="submitData('addForm')"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
import qs from "qs";
var apiServer = "http://39.105.155.249:3000"
//var apiServer = "http://localhost:3000"
export default {
  data() {
    return {
      title:"",
      oldID:"",
      types: [
        {
          value: 0,
          label: "KB-IO-449",
        },
        {
          value: 1,
          label: "KB-IO-smart",
        }
      ],
      formdata: {},
      rules: {
        id: [
          { required: true, message: "请输入ID", trigger: "blur" },
          { min: 6, max: 6, message: "ID号长度为6", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    dialogVisible: Boolean,
    addNew:Boolean,
    deviceImf:Object
  },
  
  methods: {
    handleClose() {
      this.formdata.id = "";
      this.formdata.name = "";
      this.$emit("update:dialogVisible", false);
    },
    submitData(formName) {
      let that = this;
      this.formdata.serial = localStorage.getItem('serial');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url='';
          let data = {};
          if(this.$props.addNew){
            url = apiServer + "/addDevice";
            data = qs.stringify(this.formdata);
          }
          else{
            url = apiServer + "/editDevice";
            data = qs.stringify(this.formdata);
          }
          axios
            .post(url, data, {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
            .then((relust) => {
              data = relust.data;
              if(data.code == 0){//添加成功
                that.$emit('addSucced',data.payload);
              }
              else if(data.code == 1){//更新成功
                 that.$emit('updateSucced',this.formdata);
              }
              that.$alert(data.message, "提示");
            })
            .catch((e) => {
              console.log(e);
            });
        }
        else{
            that.$alert('参数错误', "提示");
        }
      });
    },
    onDialogOpen(){
      this.formdata = this.$props.deviceImf;
      this.$props.addNew ? this.title='添加设备':this.title='编辑设备';
      if(!this.$props.addNew){
        this.formdata.oldID = JSON.parse(JSON.stringify(this.formdata.id));
      }
    }
  },
};
</script>

<style lang="less" scoped>
.add-form /deep/ .el-form-item__content {
  text-align: left;
}
</style>