<template>
  <div class="device-container">
    <div style="text-align: right;margin-right:80px;">
      <el-button
        type="text"
        @click="addButtton_click"
      >添加设备</el-button>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        height="100%"
        :cell-style="stateStyle"
      >1
        <el-table-column
          prop="id"
          label="ID"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="typestr"
          label="设备型号"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="stateStr"
          label="运行状态"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="onlineStr"
          label="在线状态"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="addtime"
          label="添加时间"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="editButton_click(scope.row)">编辑</el-button>
            <el-button
              size="mini"
              @click="delButton_click(scope.row)"
            >删除</el-button>
            <el-button
              size="mini"
              @click="optionButton_click(scope.row)"
            >设置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <add-device
      :dialogVisible.sync="dialogVisble"
      :addNew="addNew"
      :deviceImf="deviceImf"
      @addSucced="handAddSucced"
      @updateSucced="handUpdateSucced"
    ></add-device>
    <alarm-option :dialogVisible.sync="alarmOptionDialogVisble" :id='id'></alarm-option>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import AddDevice from "../components/AddDevice.vue";
import AlarmOption from "../components/AlarmOption.vue";
var apiServer = "http://39.105.155.249:3000"
//var apiServer = "http://localhost:3000"
export default {
  data() {
    return {
      tableData: [],
      dialogVisble: false,
      alarmOptionDialogVisble:false,
      addNew: true,
      deviceImf: { type: 0, name: "", id: "", address: "" },
      id:''
    };
  },
  props:{
    deviceState:Object
  },
  components: {
    AddDevice,
    AlarmOption
  },
  mounted() {
    this.get_device()
      .then((data) => {
        data.forEach((item) => {
          if(item.type*1 === 0){
            item.typestr = "KB-IO-449";
          }
          else{
            item.typestr = "KB-IO-smart";
          }
          item.state == 0 ? (item.stateStr = "停机") : (item.stateStr = "运行");
          item.online == 0 ? (item.onlineStr = "离线") : (item.onlineStr = "在线");
        });
        this.tableData = data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    get_device() {
      let serial = localStorage.getItem('serial');
      if(serial === '' || serial === undefined)
      return;
      let url = apiServer + "/devices?serial="+serial;
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    stateStyle(row){
      if(row.columnIndex == 3){
        if(row.row.state == 0){
          return {color:'red'}
        }
        else{
          return {color:'green'}
        }
      }
      else if(row.columnIndex == 4){
        if(row.row.online == 0){
          return {color:'red'}
        }
        else{
          return {color:'green'}
        }
      }
    },

    addButtton_click() {
      this.addNew = true;
      this.deviceImf = { type: 0, name: "", id: "", address: "" };
      this.dialogVisble = true;
    },
    handAddSucced(data) {
      data.typestr = "KB-IO-449";
      data.state = 0;
      data.online = 0;
      data.stateStr = '停机';
      data.onlineStr = '离线';
      this.tableData.push(data);
      this.$emit("addSucced", data);
    },
    handUpdateSucced(data){
      for(let i =0;i< this.tableData.length;i++){
        if(data.id == this.tableData[i].id){
          this.tableData[i].name = data.name;
          this.tableData[i].address = data.address;
          this.$emit('updateSucced',data);
          break;
        }
      }

    },
    Button_click(row) {
      this.$confirm("确定删除此设备", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let that = this;
        let url = apiServer + "/delDevice";
        axios
          .post(url, qs.stringify({ id: row.id }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((result) => {
            let pos = that.tableData.indexOf(row);
            that.tableData.splice(pos, 1);
            that.$emit("delSucced", row.id);
            that.$alert(result.data.message, "提示");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    editButton_click(row){
      this.addNew = false;
      this.deviceImf.id = row.id;
      this.deviceImf.type = row.type;
      this.deviceImf.name = row.name;
      this.deviceImf.address = row.address;
      this.dialogVisble = true;
    },

    optionButton_click(row){
      this.id = row.id;
      this.alarmOptionDialogVisble= true;
    }
  },

  watch:{
    deviceState:{
      handler:function(val){
        this.tableData.forEach(item=>{
          if(item.id == val.id){
            val.online == 0 ? item.onlineStr="离线":item.onlineStr="在线";
            val.state == 0 ? item.stateStr="停机":item.stateStr="运行";
            item.online = val.online;
            item.state = val.state;
          }
        })
      },
      deep:true
    }
  },
};
</script>

<style lang="less" scoped>
.device-container {
  margin: 0 auto;
}

.table-container {
  border-top: 1px solid rgb(70, 62, 88);
  height: calc(100vh - 106px);
}
</style>
