<template>
  <el-dialog
    title="报警设置"
    :visible.sync="dialogVisible"
    width="35%"
    :before-close="handleClose"
    :destroy-on-close="true"
    @opened="onDialogOpen"
  >
    <el-form
      label-width="120px"
      size="mini"
    >
    <el-form-item label="设备ID：">
        <el-input v-model="id" :disabled="true"></el-input>
    </el-form-item>
    <el-form-item label="电流低限值：">
        <el-input v-model="formData.min_i"></el-input>
    </el-form-item>
    <el-form-item label="电流高限值：">
        <el-input v-model="formData.max_i"></el-input>
    </el-form-item>
    <el-form-item label="电压低限值：">
        <el-input v-model="formData.min_u"></el-input>
    </el-form-item>
    <el-form-item label="电压高限值：">
        <el-input v-model="formData.max_u"></el-input>
    </el-form-item>
    <el-form-item label="温度高限值：">
        <el-input v-model="formData.max_t"></el-input>
    </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="setOption"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
import qs from "qs";
var apiServer = "http://39.105.155.249:3000"
export default {
  data() {
    return {
        formData:{
        }
    };
  },
  props: {
    dialogVisible: Boolean,
    id:String
  },

  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
      this.formData = {};
    },

    getOption(){
        let url = apiServer + '/getOption';
        axios.get(url,{
            params:{
               id:this.$props.id 
            }
        }).then(res=>{
            this.formData = res.data.option[0];
        }).catch(e=>{
            console.log(e);
        })
    },

    setOption(){
        let url = apiServer + '/setOption';
        this.formData.id = this.$props.id;
        let data = qs.stringify(this.formData);
        axios
            .post(url, data, {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }).then(res=>{
                this.$alert(res.data.message, "提示");
            }).catch(e=>{
                this.$alert("设置失败", "提示");
                console.log(e);
            })
    },

    onDialogOpen(){
        this.getOption();
    }
  },
};
</script>

<style lang="less" scoped>
.add-form /deep/ .el-form-item__content {
  text-align: left;
}
</style>